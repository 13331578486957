import { Component, Prop } from 'vue-property-decorator';

import { Button, Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';
import { LocaleMessage } from 'vue-i18n';
import { LabelItem, LabelListInterface } from './types';

import { Style } from '~/components/atoms/button/Button';

const rootClass = 'czt-grid-list';

@Component
export default class LabelList extends VueComponent<LabelListInterface>
  implements LabelListInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true, type: Array })
  public items!: LabelItem[];

  @Prop({ required: true })
  public title!: string | LocaleMessage;

  @Prop({ type: String, required: true })
  public guid!: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  public className = CztWidgets.GRID;

  public render() {
    const containerClasses = [rootClass, 'czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')} id={this.anchorId}>
        <v-row>{this.getHeadline()}</v-row>
        <v-row>
          <v-col class='py-0'>
            <v-row class={`${rootClass}__container`}>
              {this.getGridItems()}
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    );
  }

  protected getHeadline() {
    return (
      <v-col class='py-0'>
        <Headline underscore align={Align.LEFT} level={2}>
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected getGridItems() {
    return this.items.map((item) => (
      <v-col cols='auto'>
        <Button
          url={item.link}
          forcedLocale={item.forcedLocale}
          buttonStyle={Style.SECONDARY}
        >
          {`# ${item.title}`}
        </Button>
      </v-col>
    ));
  }
}
